import React, { useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";

export const Social = () => {
  const [image, setImage] = useState(false);
  const [image2, setImage2] = useState(false);
  return (
    <div className="flex xl:flex-col  items-center gap-5">
      {" "}
      <div className="flex items-center gap-3 justify-center">
        <a
          href="https://t.me/mogcoinpls"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {" "}
          <FaTelegramPlane className="size-[24px]" />
        </a>
        <a
          href="https://x.com/mogonpulse"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {" "}
          <RiTwitterXFill className="size-[24px]" />
        </a>

        {/* Dexscreener  */}
        <a
          href="https://dexscreener.com/pulsechain/0x495d9b70480A22a82D0FB81981480764BA55550e"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setImage(true)}
          onMouseLeave={() => setImage(false)}
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {!image ? (
            <img src={"/assets/images/dex_off.png"} alt="dexscreener mog coin" />
          ) : (
            <img src={"/assets/images/dex_on.png"} alt="dexscreener mog coin" />
          )}
        </a>

        {/* Raydium */}
        <a
          href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setImage2(true)}
          onMouseLeave={() => setImage2(false)}
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {!image2 ? (
            <img src={"/assets/images/pulsex-off.png"} alt="pulsex buy $mog" />
          ) : (
            <img src={"/assets/images/pulsex_on.png"} alt="pulsex buy $mog" />
          )}
        </a>
      </div>
    </div>
  );
};
