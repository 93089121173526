import React from "react";

function About() {
  return (
    // <div className="md:mt-20 bg-[#550BCD] relative">
    <div className="md:mt-20  relative">
      <img
        src="/assets/images/circle.png"
        className="absolute right-0 -top-80 md:hidden hidden"
        alt="circle"
      />

      <div className="flex flex-row-reverse mt-40 md:mt-20  lg:flex-col-reverse justify-start items-center gap-32 lg:gap-10 max-w-[1500px] mx-auto text-white xxl:px-4">
        {/* <div className="w-1/2 mt-20 lg:mt-[-50px] relative z-20 lg:w-full space-y-5"> */}
        <div className="w-1/2  md:mt-0 relative z-20 lg:w-full space-y-5">
          <div className=" p-10  rounded-[20px] bg-[#6623D2]  z-30">
            <h1 className="text-5xl md:text-3xl text-nowrap text-primary uppercase font-MonumentExtendedUltrabold mb-10">
              mog
            </h1>
            <p className="font-MonumentExtended text-base relative z-10">
              $MOG is the internet’s first culture coin.
            </p>
            <br />
            <p className="font-MonumentExtended text-base relative z-10">
            Mog Coin on PulseChain is a token launched by a dedicated team. Its value is supported by its burned LP, which was initially established in the project's liquidity. The entire supply was launched on 9mm Dex and then sent to the official PulseChain 0x...369 burn address. PulseChain Moggers are serious cousins, serious as a $MOG attack.            </p>
            <br />
            <p className="font-MonumentExtended text-base relative z-10">
            Mogging is the meta of this crypto cycle, and we firmly believe that PulseChain will be the foremost blockchain. We provide effective leverage on PLS in a fun and community-focused way. Join our weekly Twitter Space to chill with the multichain Mog Coin community.
            </p> 
          </div>
        </div>
        <div className="w-1/2 md:w-full mx-auto relative z-20">
          <div className="flex justify-center gap-0">
            {/* <img
              src="/assets/images/andy-tate-full-body.png"
              alt="andy-tate"
              className="size-1/2 relative z-50 md:mb-20 mx-auto bounce-animation"
            /> */}
            <video
              // autoPlay
              loop
              playsInline
              autoPlay
              muted
              controls={false}
              className="mx-auto  rounded-lg "
              style={{ objectPosition: "center" }}
            >
              {/* <source src="/DionTrainingSolutions.mp4" type="video/mp4" /> */}
              <source
                src="/assets/videos/WOLF OF MOG STREET 01 (1).mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
