import logo from "./logo.svg";
import "./App.css";
import { FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import Car from "./components/Car/Car";
import { Hero } from "./components/Hero/Hero";
import About from "./components/About/About";
import Supply from "./components/Supply/Supply";

function App() {
  return (
    <>
      <div className="nest">
        <Hero />
        <Car />
        <About />
        <div className="">
          {/* <div className="-mt-[300px] xl:mt-0"> */}
          <Supply />
        </div>
      </div>
    </>
  );
}

export default App;
