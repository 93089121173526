import React from "react";
import { InfinityCar } from "../InfinityCar";
import car1 from "../Car/car2.png";
import car2 from "../Car/car3.png";
import car3 from "../Car/car4.png";
import car4 from "../Car/car6.png";
import car5 from "../Car/car7.png";
import { InfinityText } from "../InfinityText";
const testimonials = [
  {
    img: car1,
  },
  {
    img: car2,
  },
  {
    img: car3,
  },
  {
    img: car4,
  },
  {
    img: car5,
  },
  {
    img: car1,
  },
  {
    img: car2,
  },
  {
    img: car3,
  },
  {
    img: car4,
  },
  {
    img: car5,
  },
  {
    img: car1,
  },
  {
    img: car2,
  },
  {
    img: car3,
  },
  {
    img: car4,
  },
  {
    img: car1,
  },
  {
    img: car5,
  },
  {
    img: car1,
  },
  {
    img: car2,
  },
  {
    img: car3,
  },
  {
    img: car4,
  },
];
const testimonials1 = [
  {
    quote: "$MOG ON PULSE",
  },
  {
    quote: "////",
  },
  {
    quote: "FASTER, BETTER, CHEAPER MOGCHAIN",
  },
  {
    quote: "////",
  },
  {
    quote: "SERIOUS COUSINS",
  },
  {
    quote: "////",
  },
  {
    quote: "WEEKLY TWITTER SPACES",
  },
  {
    quote: "////",
  },
  {
    quote: "$MOG ON PULSE",
  },
  {
    quote: "////",
  },
  {
    quote: "FASTER, BETTER, CHEAPER MOGCHAIN",
  },
  {
    quote: "////",
  },
  {
    quote: "SERIOUS COUSINS",
  },
  {
    quote: "////",
  },
  {
    quote: "WEEKLY TWITTER SPACES",
  },
  {
    quote: "////",
  },
  {
    quote: "$MOG ON PULSE",
  },
  {
    quote: "////",
  },
  {
    quote: "FASTER, BETTER, CHEAPER MOGCHAIN",
  },
  {
    quote: "////",
  },
];

const Car = () => {
  return (
    <div className=" -mt-60 xxl:pt-80 xl:!pt-20 xl:mt-0  md:mt-10 relative  overflow-hidden">
      {/* <InfinityCar items={testimonials} direction="right" speed="fast" /> */}
      <InfinityText items={testimonials1} direction="right" speed="fast" />
    </div>
  );
};

export default Car;
