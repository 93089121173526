import React, { useState, useEffect } from "react";
import { IoCopyOutline, IoCheckmarkOutline } from "react-icons/io5";

import { Social } from "../Social/Social";

const Supply = () => {
  const address = "0x495d9b70480A22a82D0FB81981480764BA55550e";

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="relative z-10   pt-20">
      <img
        src="/assets/images/circle2.png"
        className="absolute left-0 -top-80  h-[60%] hidden"
        alt="circle2"
      />

      {/* <img
        src="/assets/images/circle.png"
        className="absolute right-0 bottom-0 lg:hidden"
        alt="circle"
      /> */}

      <div className="max-w-5xl lg:mx-4 relative z-10 mx-auto bg-[#6623D2] flex md:flex-col md:divide-x-0 md:divide-y-0 justify-center items-center divide-x divide-primary/20 rounded-[34px] py-20 p-20">
        <div className="px-10 md:py-10 md:border-b border-primary/20 text-center">
          <p className="font-MonumentExtended text-[30px]">420.69M</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            total supply
          </p>
        </div>

        <div className="px-10 md:py-10 md:!border-b border-primary/20 text-center">
          <p className="font-MonumentExtended text-[30px] text-nowrap">0 / 0</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            tax
          </p>
        </div>

        <div className="px-10 md:py-10 text-center">
          <p className="font-MonumentExtended text-[30px]">Audited/Renounced</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            Contract Status
          </p>
        </div>
      </div>

      <h1 className="font-MonumentExtended break-all my-20  text-[30px] underline text-center xxl:px-4">
        <a href="mailto:andytatesol@proton.me">mogcoin@omnihq.pro</a>
      </h1>
      <div className=" flex justify-center mb-20">
        <Social />
      </div>

      <div className="flex  z-30 overflow-hidden  text-black bg-primary  relative w-full h-fit xxl:px-4">
        <div className="flex items-center justify-center gap-5 py-3 z-30 text-black relative w-full h-fit">
          <h1 className="font-MonumentExtended break-all text-[26px] md:text-xl text-center">
            {address}
          </h1>
          <div
            className="flex-none cursor-pointer z-20"
            onClick={handleCopyClick}
          >
            {isCopied ? (
              <IoCheckmarkOutline fontSize={24} />
            ) : (
              <IoCopyOutline fontSize={24} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supply;
