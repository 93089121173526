import { cn } from "../utils/cn";
import React, { useEffect, useRef, useState } from "react";

export const InfinityText = ({
  items,
  direction = "right",
  speed = "fast",
  pauseOnHover = true,
  className,
}) => {
  const containerRef = useRef();
  const scrollerRef = useRef();

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "right") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };
  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "10s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };
  return (
    <div
      ref={containerRef}
      className={cn(
        "scroller relative  max-w-full overflow-hidden scale-105",
        className
      )}
      style={{ scale: 1.1 }}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          " flex bg-primary min-w-full shrink-0 relativ  w-max flex-nowrap",
          start && "animate-scroll "
          //   pauseOnHover && "hover:[animation-play-state:paused]"
        )}
      >
        {items.map((item, idx) => (
          <h1
            className=" md:w-fit  text-[#7B01FD] text-[24px] px-5 py-3 font-MonumentExtended max-w-full relativ flex-shrink-0     flex justify-center items-center"
            // style={{
            //   background:
            //     "linear-gradient(180deg, var(--slate-800), var(--slate-900)",
            // }}
            key={item.name}
          >
            {/* <blockquote> */}
            {/* <div
                aria-hidden="true"
                className="user-select-none  z-50  pointer-events-none absolute -left-0.5 -top-0.5 h-[calc(100%_+_4px) w-[calc(100%_+_4px)]"
              ></div> */}
            <div className="flex items-center gap-5 relative z-50  justify-center ">
              {/* <img
                src={item?.img}
                className="relative z-50 w-full object-contain object-center"
                alt="logo"
                srcset=""
              /> */}
              <h1
                className="  z-20 text-[18px]   text-[#7B01FD] font-normal "
                style={{ textAlign: "center" }}
              >
                {item.quote}
              </h1>
              {/* <div>
                  <span
                    className="inline-block relative top-[2px]  z-20 text-[18px]  text-gray-100 font-normal "
                    // style={{ lineHeight: "6px" }}
                  >
                    {item.quote}
                  </span>
                </div> */}
              {/* <span className="text-white text-center text-lg text-nowrap items-center justify-center mx-auto  inline-block ">
                  {item.quote}
                </span> */}
            </div>
            {/* </blockquote> */}
          </h1>
        ))}
      </ul>
    </div>
  );
};
